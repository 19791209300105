







































import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
  }
})
export default class Navbar extends Vue {

  changeLocale(locale: string) {
    this.$i18n.locale = locale;
    const accepted = localStorage.getItem("nettavle.accept.cookies") as boolean | null;
    if (accepted) {
      localStorage.setItem("nettavle.default.language", locale);
    }
  }

  imageUrl(flag: string) {
    return require("@/assets/flags/" + flag + ".svg");
  }


}
