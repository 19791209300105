import Vue from 'vue';
import VueI18n from 'vue-i18n';
import store from '../store/index';

Vue.use(VueI18n);

// @ts-ignore
import da_DK from "@/plugins/json/text_da_DK.json";
// @ts-ignore
import en_GB from "@/plugins/json/text_en_GB.json";

const languages: Array<any> = [
    { flag: 'dk', language: 'da_DK', title: 'Dansk' },
    { flag: 'gb', language: 'en_GB', title: 'English' }
];

store.commit('SET_LANGUAGES', languages);

const messages = {
    da_DK,
    en_GB
};

const stored = localStorage.getItem("nettavle.default.language");
const locale: string = stored !== null ? stored : 'da_DK';

const i18n = new VueI18n({
    locale: locale, // set locale
    fallbackLocale: 'da_DK', // set fallback locale
    messages, // set locale messages
});

export default i18n;