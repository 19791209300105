


















































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import emailjs from 'emailjs-com';
import { TranslateResult } from 'vue-i18n';

@Component({
  components: {
  }
})
export default class ContactForm extends Vue {

  @Prop()
  showFullForm?: boolean;

  name = "";
  company = "";
  phone = "";
  email = "";
  users = "";
  comments = ""

  async onSubmit(e: any) {
    try {
      let response: any = await emailjs.sendForm(
          'service_d14jf2i',
          this.showFullForm ? 'template_kmyyvmn' : 'template_tfiwib7',
          e.target ,
          'user_ZXpOuoM2CpS8UbglPvCdV')
      //@ts-ignore
      this.makeToaster( this.$i18n.t("toast.success.title"),this.$i18n.t("toast.success.text"),'success');
      this.reset();
    } catch(error) {
      //@ts-ignore
      this.makeToaster( this.$i18n.t("toast.failure.title"),this.$i18n.t("toast.failure.text"),'danger');
    }
  }

  reset() {
    this.name = "";
    this.company = "";
    this.phone = "";
    this.email = "";
    this.users = "";
    this.comments = ""
  }

  makeToaster(title: TranslateResult, text: TranslateResult, variant: string) {
    this.$bvToast.toast(text as string, {
      title: title as string,
      autoHideDelay: 7000,
      appendToast: true,
      solid: true,
      variant: variant,
      toaster: 'b-toaster-bottom-right'
    })
  }


}
