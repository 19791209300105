

















































import { Vue, Component } from 'vue-property-decorator';
import ContactForm from "@/components/ContactForm.vue";

@Component({
  components: {ContactForm}
})
export default class Prices extends Vue {


}


