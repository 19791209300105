import Vue from 'vue'
import Vuex from 'vuex'
import {RootState} from "@/store/statetypes";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    languages: [],
    showCookieModal: true,
  },
  mutations: {
    SET_LANGUAGES(state: RootState, languages: Array<ILanguageSet>) {
      state.languages = languages;
    },
    SET_SHOW_COOKIE_MODAL(state: RootState, show: boolean) {
      state.showCookieModal = show;
    },

  },
  actions: {},
  modules: {}
});
