
































import { Component, Vue } from "vue-property-decorator";
import Navbar from "@/components/Navbar.vue";
import {Action, Mutation} from "vuex-class";


@Component({
  components: {
    Navbar
  }
})
export default class App extends Vue {

  @Mutation("SET_SHOW_COOKIE_MODAL") setShowCookieModal: any;

  mounted() {
    const accepted = localStorage.getItem("nettavle.accept.cookies") as boolean | null;
    if (!accepted) {
      //@ts-ignore
      this.$refs["cookie-modal"].show();
    }

  }

  acceptCookies() {
    localStorage.setItem('nettavle.accept.cookies', 'true');
    this.setShowCookieModal(false);
    //@ts-ignore
    this.$refs['cookie-modal'].hide()
  }


}
